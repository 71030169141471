import React from "react"
import "../components/layout.css"
import { Helmet } from "react-helmet"
import {useLocation} from '@reach/router'

// Import Component
import Footer from "../components/FooterComponents/Footer"
import HeaderHowItWorks from "../components/HeaderComponents/HeaderHowItWorks"
import ProcressSection from "../components/ProcessSection/ProcessSection"

// Import Json
import { MainPageJson, PathJson, HowItWorksJson } from "../components/location"

function HowItWorks() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const HowItWorksContent = HowItWorksJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes('/en')

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {isEnglish
            ? "How It Works | Transfez"
            : "Cara Kerja | Transfez"}
        </title>
      </Helmet>
      <HeaderHowItWorks
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={HowItWorksContent}
      />
      <ProcressSection props={HowItWorksContent} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent}/>
    </>
  )
}

export default HowItWorks
