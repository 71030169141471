import React from "react"
import { useLocation } from "@reach/router"
import { Container, Row, Col } from "react-bootstrap"
import {
  StyledSection,
  HowItWorksH3,
  HowItWorksLogoDiv,
  HowItWorksLogo,
  HowItWorksCol,
  ProcessNumber1,
  ProcessNumber2,
  ProcessNumber3,
  Ornament,
  SmallStyled,
  TransfezLink,
  Row2
} from "./ProcessStyle"

import logoTransfez from "../../images/logo-transfez-new.svg"
import hiw1 from "../../images/hiw-1.svg"
import hiw2 from "../../images/hiw-2.svg"
import hiw3 from "../../images/hiw-3.svg"
import hiwImages1 from "../../images/hiw-1-images.svg"
import hiwImages2 from "../../images/hiw-2-images.svg"
import hiwImages3 from "../../images/hiw-3-images.svg"
import hiwOrnament from "../../images/hiw-ornament.png"

function ProcessSection({ props }) {
  const location = useLocation()
  const lang = location.pathname.includes("/en")
  const {
    Title,
    Brand1,
    Brand2,
    Navigate,
    FirstOne,
    FirstTwo,
    FirstThree,
    SecondOne,
    SecondTwo,
    SecondSmall,
    ThirdOne,
    ThirdTwo,
  } = props.Process

  return (
    <>
      <StyledSection className="pt-5 bg-white position-relative">
        <HowItWorksH3>{Title}</HowItWorksH3>
        <Container>
          <HowItWorksLogoDiv>
            <HowItWorksLogo src={logoTransfez} alt="logo-transfez"/>
          </HowItWorksLogoDiv>
          <Row className="mb-5">
            <Col md={7}>
              <img src={hiwImages1} alt="images1" width="100%" />
            </Col>
            <HowItWorksCol md={5}>
              <ProcessNumber1 src={hiw1} alt="1" />
              <p>
                {FirstOne} <TransfezLink to={Navigate}>{Brand1}</TransfezLink>{" "}
                {FirstTwo}{" "}
                <TransfezLink to={Navigate}>
                  {lang ? Brand2 : Brand1}
                </TransfezLink>{" "}
                {FirstThree}
              </p>
            </HowItWorksCol>
          </Row>
          {/* nomor2 */}
          <Row2 className="mb-5">
            <HowItWorksCol md={5}>
              <ProcessNumber2 src={hiw2} alt="3" />
              <p className="mb-3">
                <TransfezLink to={Navigate}>{Brand1}</TransfezLink>{SecondOne}
                <TransfezLink to={Navigate}>{Brand1}</TransfezLink> {SecondTwo}
              </p>
              <SmallStyled>{SecondSmall}</SmallStyled>
            </HowItWorksCol>
            <Col md={7}>
              <img src={hiwImages2} alt="images2" width="100%" />
            </Col>
          </Row2>
          {/* nomor3 */}
          <Row className="mb-5">
            <Col md={7}>
              <img src={hiwImages3} alt="images3" width="100%" />
            </Col>
            <HowItWorksCol md={5}>
              <ProcessNumber3 src={hiw3} alt="3" />
              <p>
                {ThirdOne}{" "}
                <TransfezLink to={Navigate}>
                  {lang ? Brand2 : Brand1}
                </TransfezLink>{" "}
                {ThirdTwo}
              </p>
            </HowItWorksCol>
          </Row>
        </Container>
        <Ornament src={hiwOrnament} alt="ornament" />
      </StyledSection>
    </>
  )
}

export default ProcessSection
