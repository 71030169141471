import styled from "styled-components"
import { Col, Row } from "react-bootstrap"
import bgCloud from "../../images/hiw-cloud.svg"
import {Link} from 'gatsby'

export const StyledSection = styled.section`
  padding-bottom: 407px;
  background-image: url(${bgCloud});
  background-size: contain;
  background-position: center bottom -1px;
  background-repeat: no-repeat;
  @media (max-width:414px){
    padding-bottom: 115px;
  }
`

export const HowItWorksH3 = styled.h3`
  width: 336px;
  color: #48a9b5;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-family: "GothamLight";
  background-color: white;
  border-radius: 10px;
  margin: 0 auto;
  padding: 15px 30px;
  position: absolute;
  top: -50px;
  left: calc(50% - (336px / 2));
`

export const HowItWorksLogoDiv = styled.h5`
  text-transform: uppercase;
  font-weight: medium;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`
export const HowItWorksLogo = styled.img`
  width: 300px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const HowItWorksCol = styled(Col)`
  position: relative;
  padding-top: 75px;
  z-index: 2;
`

export const Row2 = styled(Row)`
  @media (max-width:414px){
    flex-direction: column-reverse;
  }
`

export const ProcessNumber1 = styled.img`
  left: -120px;
  position: absolute;
  top: 10px;
  @media (max-width: 414px) {
    left:unset;
    right:15px;
    top:-15px;
  }
`
export const ProcessNumber2 = styled.img`
  right: -120px;
  position: absolute;
  top: 10px;
  @media (max-width: 414px) {
    right:unset;
    left:15px;
    top:-15px;
  }
`
export const SmallStyled = styled.small`
  font-family:"GothamLight";
  color: #48a9b5;
`

export const ProcessNumber3 = styled.img`
  left: -120px;
  position: absolute;
  top: 10px;
  @media (max-width: 414px) {
    left:unset;
    right:15px;
    top:-15px;
  }
`
export const Ornament = styled.img`
  position: absolute;
  bottom: 75px;
  left: calc(50% - 356px);
  @media (max-width:414px){
    width: 300px;
    left: calc(50% - 150px);
    bottom: -5px;
  }
`
export const TransfezLink = styled(Link)`
  font-family: "GothamLight";
  color: rgb(72,169,181);
`
