import React from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import NavigationBar from "./NavigationBar"

import hiwHeader from "../../images/hiw-header.svg"
import {
  HiwHeader,
  HiwCol,
  HiwHeaderImg,
  HiwContainer,
  HiwRow,
  HiwH2,
  HiwP,
  HiwCol2,
} from "./HeaderStyle"

function HeaderHowItWorks({ props, propsLink, propsData }) {
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = (status) => {
    setStatusNav(status)
  }

  return (
    <HiwHeader style={statusNav ? {paddingTop: "80px"}:null}>
      <NavigationBar props={props} propsLink={propsLink} parentCallback={statusNavbar}/>
      <HiwContainer>
        <HiwRow>
          <HiwCol>
            <HiwH2>{propsData.Header.Title}</HiwH2>
            <HiwP>{propsData.Header.Desc}</HiwP>
          </HiwCol>
          <HiwCol2>
            <HiwHeaderImg src={hiwHeader} alt="header"/>
          </HiwCol2>
        </HiwRow>
      </HiwContainer>
    </HiwHeader>
  )
}

export default HeaderHowItWorks
